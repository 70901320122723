<template>
<v-lazy transition="scroll-x-transition">

<v-main>


              <div class="space">
          <v-container>
            <v-row>
              <v-col>


              <v-content>
                

              <form  @submit.prevent="sendEmail">
                <v-text-field 
                  name="name"
                  v-model="name"
                  placeholder="Your Name"
                  :error-messages="nameErrors"
                  label="Name"
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
                  required
                 
                ></v-text-field>
                <v-text-field
                  name="email"
                  v-model="email"
                  placeholder="Your E-Mail"
                  :error-messages="emailErrors"
                  label="E-mail"
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                  required
                
                ></v-text-field>
               <v-textarea
                  name="message"
                  v-model="message"
                  placeholder="Message"
                  counter
                  label="Message"
                  :value="value"
                ></v-textarea>
                   <v-row>
                     <v-col>
                 <!-- <div class="space"> -->
              <v-card-actions>
            <div class="round">
              <v-btn type="submit" color="success">
                Submit
              </v-btn>
            </div>
            </v-card-actions>
                     </v-col>
                   </v-row>
           </form>
      </v-content>
              </v-col>
            </v-row>
      </v-container>
              </div>
              <!-- <v-alert
               type="success"
               outlined
               
             ></v-alert> -->
    </v-main>
    </v-lazy>
</template>

<script>
import emailjs from 'emailjs-com';


import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

  export default {
    name: 'Form',
    mixins: [validationMixin],


    validations: {
      name: { required },
      email: { required, email },
      select: { required }
      },
    data() {
      return{
        name: '',
        email: '',
        message: '',
         }
      },
  
  computed: {

      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.required && errors.push('Name is required.')
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('Must be a valid e-mail')
        !this.$v.email.required && errors.push('E-mail is required')
        return errors
      },
    },
     methods: {
    sendEmail(e) {
      this.$v.$touch()
      if(this.$v.name.$error || this.$v.email.$error){
        return alert('please enter your Name & a valid E-mail address')
      }else{
        emailjs.sendForm('TheCodeEffect', 'template_tzdddhu', e.target, 'user_qXEzwpJLJHTnxF3SRRYYo')
        .then(() => {
          alert('Thank you for contacting The Code Effect. We will get back to you within 48 hours.')
          // this.$router.push('/');
            // console.log('SUCCESS!', result.status, result.text);
        }, (error) => {
          console.log('FAILED...', error);
        });
          }
    }
  }
  
  }
</script>
