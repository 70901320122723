<template>
<v-main>
    <Header></Header>
    <div class="section5">

       <v-row>
                <v-col> 
                    <div class="aurora2">

        <v-img max-height="30em" min-height="20em" contain :src="require('../assets/Resized Pictures/_DSC4738.webp')">
         <!-- <v-card-title  class="justify-center"  style="margin-top:5em;"  primary-title>
             <h1>
             Contact Us
             </h1>
         </v-card-title> -->
         </v-img> 

             </div>
                </v-col>
            </v-row>

    <v-container>
        <v-row>
            <v-col>

        
    <h1>Get in Touch with Us</h1>
    <h3>Interested in learning more about our services? Our team takes the time to discuss your ideas and provides consultation to help you make smart decisions that best meet your needs.
       We’d love to hear from you.<br> Use the form below or drop us an email at contact@thecodeeffect.com
         </h3>
            </v-col>
        </v-row>
    </v-container>

    <Form></Form>
   
   <v-container >
    <!-- <div class="space"> -->

       <v-row>
           <v-col cols="12" lg="6" md="6" sm="12">
                   <!-- <div class="space"> -->
                       <!-- <v-card color="rgb(0,0,0,0)"> -->
                           

               <v-card-title>
               <h2>Come visit us</h2>
               </v-card-title>
               <v-card-text>
               <p>We are located in Sudan, Khartoum, Burri Allamab, Square 5 , Building No.234</p>
               </v-card-text>
                       <!-- </v-card> -->
                   <!-- </div> -->
           </v-col>
           <v-col cols="12" lg="6" md="6" sm="12">

       <div class="space">

   <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d960.6917775231913!2d32.57451482922192!3d15.604086999323101!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTXCsDM2JzE0LjciTiAzMsKwMzQnMzAuMiJF!5e0!3m2!1sen!2s!4v1616680009875!5m2!1sen!2s" class="iframe" loading="lazy">
   </iframe>
       </div>
           </v-col>
       </v-row>
    <!-- </div> -->
   </v-container>
  <Footer></Footer>
    </div>
</v-main>
</template>
<script>
 import Header from '../components/Header'
 import Footer from '../components/Footer.vue'
 import Form from '../components/Form'



export default {
    name: 'Contact',
    components:{
    Header,
    Footer,
    Form,
    },
    
   
}
</script>

